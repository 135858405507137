<template>
  <MobileHeader
    :mobileHeaderTitle="'FANTASY_SPORT'"
    :tabs="$route.name"
  ></MobileHeader>

  <div class="container has-space has-header">
    <h1 class="page-title" v-if="!isMobile">{{ $t("FANTASY_SPORT") }}</h1>
    <div>
        <iframe class="fantasy-iframe" :src=fantacyIframeUrl></iframe>
    </div>
  </div>
</template>

<script>
import MobileHeader from "@/components/mobile/Header.vue";
import { mapGetters, mapActions } from "vuex";
import config from '@/js/config.js'

export default {
  components: {
    MobileHeader,
  },
  data() {
    return {
      tabs: [
        {
          name: "MATCHES",
          pathName: "footballFavMatches",
          selected: true,
        },
        {
          name: "TEAMS",
          pathName: "footballFavTeams",
          selected: false,
        },
        {
          name: "COMPETITIONS",
          pathName: "footballFavCompetitions",
          selected: false,
        },
      ],
      currentTab: [],
      fantasySportUrl: config.fantasySportUrl,
      fantacyIframeUrl:"",
    };
  },
  computed: {
    ...mapGetters(["isMobile", "isLogin","currentLocale","userSwitchCountryLang"]),
  },
  watch: {
        isLogin: {
            deep: true,
            handler(newVal, oldVal) {
                if (!newVal) {
                  this.$router.push({ name: 'home'})
                }
            },
        },
        userSwitchCountryLang: {
          handler(newVal, oldVal) {
            location.reload();
          }
        },
  },
  mounted() {
    this.commonVerifyLogin();
     if (!this.isLogin) {
          location.href = `/${this.currentLocale}`
     } else {
      this.getProviderTokenData();
     }
  },
  methods: {
    ...mapActions([
      "commonVerifyLogin",
      "getProviderToken"
    ]),
    async getProviderTokenData() {
        let params = {
            providerId: 1
        }
        const result = await this.getProviderToken(params);
        let token = result.result.data;
        
        this.fantacyIframeUrl = `${this.fantasySportUrl}/?token=${token}`
    }
  },
};
</script>

<style>
.fantasy-iframe{
  width: 100%;
  height: 1300px;
  border: none;
  margin-top: 1rem;
}
.fantasy-iframe-logout{
    height: 0;
    border: none;
}
@media (max-width: 768px) {
}

</style>
